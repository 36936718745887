<template>
  <div class="item" @click="goDetail">
    <!-- 左边图片 -->
    <!-- <img :src="item.serviceImage" alt="0" class="img"/> -->
    <!-- 右边内容 -->
    <!-- <div> -->
      <!-- <span class="name">{{
          item.consultantName
        }}</span> -->
      <!-- <div class="hack">
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="item.serviceName">
          <span slot="reference" class="first-label">{{ item.serviceName.length > 10 ? item.serviceName.slice(0, 10) + '...' : item.serviceName }}</span>
        </el-popover>
        <span class="second-label">{{ item.level }}</span>
      </div> -->

      <!-- <div class="duration" style="margin-top: 5px; font-size: 13px">
        服务时长：{{ item.serviceDuration }}分钟
      </div> -->

        <!-- <div class="content" slot="reference" v-html="item.serviceContent">
        </div> -->
      <!-- <el-rate :value="item.score" disabled/> -->

      <!-- 底部 -->
      <div class="footer">
        <!--价格-->
        <!-- <div class="price">
          <span class="money-icon">￥</span>
          <span class="money">{{ item.price }}</span>
        </div> -->
        <!--按钮-->
        <div class="immediately" size="mini" round id="immediately">
          <img src="../assets/radio.svg" alt="" width="30px">
          <p>视频咨询</p>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";

const dayjs = require('dayjs');

export default {
  props: ['item'],
  methods: {
    ...mapMutations(['showLoginPopup']),
    goDetail() {
      if (this.$store.state.token) {
        // const detail = JSON.stringify(this.item)
        // this.$router.push({
        //   path: '/consultDetails?id=1',
        //   query: {
        //     id: detail.id
        //   }
        // })
        this.$router.push(`consultDetails?id=${this.item.id}`)
      } else {
        this.showLoginPopup();
      }
    },

    // 格式化时间
    formatDate(time, format) {
      return dayjs(time).formatDate(format)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  width: 59%;
  box-sizing: border-box;
  padding: 22px 19px 23px 10px;
  background: transparent;
  border-radius: 6px;
}

.img {
  width: 100%;
  height: 100%;
  background: pink;
  border-radius: 6px;
  object-fit: cover;
}

.name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.hack {
  display: inline-block;
  margin-left: 3px;
}

.first-label {
  box-sizing: border-box;
  margin: 0 12px 0 0;
  padding: 4px 5px 4px 5px;
  background: rgba(78, 111, 218, 0.19);
  border-radius: 100px;
  font-size: 16px;
  color: #4E6FDA;
}

.second-label {
  box-sizing: border-box;
  padding: 4px 5px 4px 5px;
  background: rgba(218, 181, 78, 0.19);
  border-radius: 100px;
  font-size: 16px;
  color: #DA864E;
}

.content {
  margin-top: 10px;
  width: 20%;
  height: 100px;
  line-height: 20px;
  font-size: 15px;
  color: #5D5D5D;
  overflow: hidden;
}

.money-icon {
  font-weight: bold;
  font-size: 16px;
  color: #535457;
}

.money {
  font-weight: bold;
  font-size: 30px;
  color: #4E6FDA;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: fixed;
  right: -160px;
  bottom: 200px;
  margin-top: 10px;
  width: 15%;
}

/deep/ .el-rate__icon {
  font-size: 30px;
}

::v-deep .el-main{
  overflow: hidden !important;
}
.immediately {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #86c2d3;
  font-size: 12px;
}
</style>
