<template>
  <div class="service-wrap">
    <!-- 咨询老师列表 -->
    <div class="item-wrap">
      <!-- 当前项 -->
      <template v-if="$route.path !== '/consultDetails'">
        <template v-if="counselorList && counselorList.length">
          <div class="item-consultant-box">
            <!--<div class="item-consultant">-->
              <el-row type="flex" :gutter="10" class="item-consultant-wrap">
                <el-col :span="8" v-for="(item, index) in counselorList" :key="item.id" style="margin-bottom: 10px">
                  <CounselorCard :item="item" />
                </el-col>
              </el-row>
            <!--</div>-->
          </div>
          <el-pagination
              :total="total"
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange">
          </el-pagination>
        </template>

        <!--数据为空时-->
        <el-empty v-else description="暂无数据啦~~" style="height: 100%" />
      </template>

      <transition mode="in-out" name="fade">
        <router-view/>
      </transition>
    </div>

  </div>
</template>

<script>
import CounselorCard from "../components/CounselorCard";
import { getCounselList } from '../api/counselService';

export default {
  components: {
    CounselorCard
  },
  data() {
    return {
      swiperOption: {
        autoplay: false,
        slidesPerView: 3, //一行显示3个
        spaceBetween: 30,
        delay: 5000,
        slidesPerColumn: 3, //显示3行
        slidesPerColumnFill: "row"
      },
      // 咨询师列表
      counselorList: [],
      total: 0, // 总数
      currPage: 1, // 当前页
    }
  },
  created() {
    this.getData(this.currPage);
  },
  methods: {
    // 获取数据
    getData(current) {
      getCounselList({
        currPage: current,
        pageSize: 9,
        state: 1  // 只查询启用的咨询服务
      }).then(({data}) => {
        this.counselorList = data.list;

        // 总数量
        this.total = data.totalCount;
      })
    },

    // 显示第几页
    handleCurrentChange(val) {

      this.getData(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.item-consultant-wrap {
  flex-wrap: wrap;
}


//.item-consultant {
//  display: grid;
//  grid-template-columns: 30% 30% 30%;
//  grid-row-gap: 30px;
//  grid-column-gap: 4%;
//  min-width: 1600px;
//  width: 100%;
//}

//.item-consultant-box {
//  height: calc(100% - 51px);
//  overflow-y: auto;
//  width: 100%;
//  overflow-x: auto;
//
//  &::-webkit-scrollbar {
//    width: 3px;
//    height: 5px;
//  }
//
//  &::-webkit-scrollbar-track {
//    background: #F2F6FC;
//    border-radius: 3px;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    background: #909399;
//    border-radius: 3px;
//  }
//
//  &::-webkit-scrollbar-thumb:hover {
//    background: #909399;
//  }
//}

.item-wrap {
  height: 100%;

}

.el-pagination {
  text-align: right;
}

.service-wrap {
  background: #F6F6F6;
  height: 100%;

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
